<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <EditBookForm :key="(book ? book.id : 0)" :value="book" :busy="bookLoading" />
  </div>
</template>

<script>
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import EditBookForm from '@/components/form/EditBookForm.vue';
import getBookMixin from '@/mixins/getbook';

export default {
  name: 'BackendEditBook',

  mixins: [getBookMixin],
  components: { BreadCrumb, EditBookForm },

  data() {
    return {
      book_id: this.$route.params.book_id,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books List', to: { name: 'bke-book-list' } },
        { text: this.book_id, to: { name: 'bke-book-view', params: { book_id: this.book_id } } },
        { text: 'Edit', active: true },
      ];
    },
  },

};
</script>
